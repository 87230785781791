<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { GameDistrictId, GameDistrictListContent } from '~/model/GameDistrict/GameDistrict'
  import useGameDistrict from '~/composables/GameDistrict/GameDistrict'
  import UiAccordion from '~/components/Ui/UiAccordion/UiAccordion.vue'
  import ListLocation from '~/components/ListLocation/ListLocation.vue'
  import ActionUnlockDistrict from '~/components/ActionUnlockDistrict/ActionUnlockDistrict.vue'
  import useBem from '~/composables/Bem/Bem'
  import { UseBemProps } from '~/composables/Bem/BemFacetOptions'
  import { ref, watch } from '#imports'
  import { GameCityId } from '~/model/GameCity/GameCity'
  import { useLocaleStore } from '~/store/Locale'
  import { useGameContentStore } from '~/store/GameContent'
  import { useGameStateStore } from '~/store/GameState'
  import { UiIconId, UiIconNameId, UiIconSeriesId, UiIconSizeId } from '~/model/UiIcon/UiIcon'
  import useTranslation from '~/composables/Translation/Translation'
  import { GameModeId } from '~/model/GameMode/GameMode'

  // NOTE: Props extension currently not supported
  // https://vuejs.org/guide/typescript/composition-api.html#typing-component-props
  interface Props extends UseBemProps {
    facets?: Array<string>
    cityId: GameCityId
  }

  const localeStore = useLocaleStore()
  const gameStateStore = useGameStateStore()

  const props = defineProps<Props>()
  const { localeId } = storeToRefs(localeStore)
  const { activeModeId, selectedDistrictId } = storeToRefs(gameStateStore)
  const { t } = useTranslation()
  const { assignPendingData } = useGameContentStore()
  const { getList, selectDistrict, isUnlocked } = useGameDistrict()
  const { bemAdd } = useBem('c-list-district', props, {})

  const districtList = ref<Array<GameDistrictListContent> | null>(null)
  const entryElRefs = ref<Array<HTMLLIElement>>([])

  const isStay = computed<boolean>(() => {
    return activeModeId.value === GameModeId.Stay
  })

  const getBtnClasses = (id: GameDistrictId): Array<string> => {
    return [
      bemAdd(isUnlocked(id) ? 'is-unlocked' : 'is-locked', 'btn'),
      bemAdd(selectedDistrictId.value === id ? 'is-open' : 'is-closed', 'btn'),
    ]
  }

  const requestSelectDistrict = (targetId: GameDistrictId) => {
    selectDistrict(selectedDistrictId.value === targetId ? undefined : targetId)
  }

  const scrollToEntry = (targetId: GameDistrictId) => {
    const targetEl = entryElRefs.value.find((entryElRef) => {
      return entryElRef.dataset.districtId === targetId
    }) as HTMLLIElement

    targetEl.scrollIntoView()
  }

  const onAccordionChanged = (isOpen: boolean, targetId: GameDistrictId) => {
    if (isOpen && !isUnlocked(targetId)) {
      scrollToEntry(targetId)
    }
  }

  watch(
    () => props.cityId,
    async (newVal) => {
      if (!newVal) {
        return
      }

      const req = await getList(localeId.value, props.cityId)
      assignPendingData<Array<GameDistrictListContent>>(req, (data) => {
        districtList.value = data.value
      })
    },
    { immediate: true },
  )
</script>

<template>
  <div class="c-list-district u-typography-root">
    <ul class="u-reset c-list-district__list">
      <li
        v-for="{ id, name } in districtList"
        :key="id"
        ref="entryElRefs"
        :data-district-id="id"
        class="c-list-district__entry"
      >
        <button
          :class="getBtnClasses(id)"
          class="c-list-district__btn"
          @click="requestSelectDistrict(id)"
        >
          <span class="c-list-district__label">{{ name }}</span>
          <UiIcon
            v-if="isUnlocked(id)"
            :id="UiIconId.IconControlMediumChevronRight"
            class="c-list-district__icon"
            :colorize="true"
            :size="UiIconSizeId.Medium"
          />
          <UiIcon
            v-if="!isUnlocked(id)"
            :id="UiIconId.IconControlMediumChevronDown"
            class="c-list-district__icon"
            :colorize="true"
            :size="UiIconSizeId.Medium"
          />
        </button>

        <UiAccordion
          :facets="[]"
          :open="selectedDistrictId === id"
          :passive="true"
          @changed="onAccordionChanged($event, id)"
        >
          <template #body>
            <div class="c-list-district__body">
              <span>{{ t('list-district.locations') }}:</span>

              <ListLocation :district-id="id" class="c-list-district__location-list" />

              <div class="c-list-district__actions">
                <ActionUnlockDistrict v-if="isStay" :district-id="id" />
              </div>
            </div>
          </template>
        </UiAccordion>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
  @use '@nirazul/scss-utils' as utils;
  @use '/assets/scss/util/transition' as trs;
  @use '/assets/scss/base/typography/typography' as type;
  @use '/assets/scss/util/color/color' as col;

  .c-list-district__list {
    border-bottom: 1px solid col.$brand-cyan;
  }

  .c-list-district__entry {
    border-top: 1px solid col.$brand-cyan;
  }

  .c-list-district__icon {
    @include trs.common-props;
    @include utils.is-visible(false, trs.$default-speed);
    opacity: 0;
  }

  .c-list-district__btn {
    @include trs.common-props;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 2px 10px 14px;
    overflow: hidden;

    &.c-list-district__btn--is-locked {
      color: col.$monochrome-quicksilver;

      @include utils.has-focus {
        color: col.$monochrome-white;

        .c-list-district__icon {
          @include utils.is-visible(true, trs.$default-speed, opacity, transform);
          opacity: 1;
        }
      }

      &.c-list-district__btn--is-open {
        color: col.$monochrome-white;

        .c-list-district__icon {
          @include utils.is-visible(true, trs.$default-speed, opacity, transform);
          opacity: 1;
          transform: rotateX(180deg);
        }
      }
    }

    &.c-list-district__btn--is-unlocked {
      cursor: pointer;
      color: col.$variant-cyan-light;

      .c-list-district__icon {
        @include utils.is-visible(true, trs.$default-speed, opacity, transform);
        opacity: 1;
      }

      @include utils.has-focus {
        color: col.$brand-cyan;

        .c-list-district__icon {
          transform: translateX(4px);
        }
      }
    }
  }

  .c-list-district__label {
    @include type.h3;

    display: block;
    text-align: left;
  }

  .c-list-district__body {
    padding: 0 14px 32px;
  }

  .c-list-district__location-list {
    margin: 24px 0 24px;
  }

  .c-list-district__actions {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }
</style>
